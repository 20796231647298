import React, { useEffect, createContext, useReducer, useContext } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { QueryClientProvider, QueryClient, useQuery } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Welcome from './pages/Welcome'
import './App.css'
import './css/navbar.css'
import { handelRightClick } from './AppUtility';



const queryClient = new QueryClient();

const Routing = () => {

  return (
    <div>
      <Routes>

        <Route exact path="/" element={<Welcome />}></Route>

      </Routes>
    </div>

  );
};

export const UserContext = createContext();


function App() {
  document.addEventListener('contextmenu', handelRightClick);

  useEffect(() => {
    return () => {
      document.addEventListener('contextmenu', handelRightClick);      
    };
  },[]);
  return (

    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routing />
      </BrowserRouter>
    </QueryClientProvider>

  );
}




export default App;
