import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import axios from "axios";
import background from "../images/img1-min.jpg";
import { ReactComponent as SliderOne } from "../images/img1.svg";
import logo_ayat from "../images/logo_ayat.png";
import { ReactComponent as ReactLogo } from "../images/ayat_logo.svg";
import { BsFillExclamationCircleFill } from "react-icons/bs";
import { moment } from "moment";

import "../css/navbar.css";

import ff from "../images/ff.pdf";
import gf from "../images/gf.pdf";
import sf from "../images/sf.pdf";
import tf from "../images/tf.pdf";
import lff from "../images/lff.pdf";

// const MyDoc = () => (
//   <Document>
//     <Page>// My document data</Page>
//   </Document>
// );

const Welcome = () => {
  const img = background;

  const [getId, setGetId] = useState("");
  const [getTitle, setGetTitle] = useState("");
  const [getResult, setGetResult] = useState(null);
  const fortmatResponse = (res) => {
    return JSON.stringify(res, null, 2);
  };
  const {
    data,
    isLoading: isSearchingTutorial,
    refetch: findTutorialsByTitle,
    isFetching,
    isError,
  } = useQuery(
    "query-tutorials-by-title", // ["query-tutorials-by-title", getTitle],
    async () => {
      return await axios.get(
        `https://shopapi.ayatrealestate.com/api/search?search=${getTitle}`
      );
    },
    {
      enabled: false,
      retry: 1,
      onSuccess: (res) => {
        const result = {
          status: res.status + "-" + res.statusText,
          headers: res.headers,
          data: res.data,
        };
        setGetResult(fortmatResponse(result));
      },
      onError: (err) => {
        setGetResult(fortmatResponse(err.response?.data || err));
      },
    }
  );
  useEffect(() => {
    if (isSearchingTutorial) setGetResult("searching...");
  }, [isSearchingTutorial]);
  function getDataByTitle() {
    if (getTitle) {
      try {
        findTutorialsByTitle();
        JSON.parse(getResult);

        if (finalData.data === {} || finalData.data === null) {
          return <div>Error</div>;
        }
        setGetResult(getResult.data);
      } catch (err) { }
    }
    // setGetTitle(null)
  }
  const clearGetOutput = () => {
    setGetResult(null);
  };

  if (isSearchingTutorial || isFetching)
    return (
      <div className="row d-flex justify-content-center align-items-center">
        <nav className="navbar navbar-light bg-gray">
          <div
            className="col-12 mb-1"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <ReactLogo style={{ width: 200, height: 200 }} />
          </div>
          <div
            className="col-12 mt-2"
            style={{ display: "flex", justifyContent: "center" }}
          >
            Loading....
          </div>
        </nav>
      </div>
    );
  if (isError)
    return (
      <div className="row d-flex justify-content-center align-items-center">
        <nav className="navbar navbar-light bg-gray">
          <div
            className="container-fluid"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <a className="navbar-brand" href="#">
              <ReactLogo style={{ width: 200, height: 200 }} />
              {/* Ayat Real State */}
            </a>
          </div>
        </nav>
        <div style={{ display: "flex", justifyContent: "center" }}>
          Something Went Wrong
        </div>
      </div>
    );

  const finalValue = JSON.parse(getResult);
  const finalData = finalValue;
  console.log(finalData)
  String.prototype.toHHMMSS = function () {
    var sec_num = parseInt(this, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    // if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return hours + ':' + minutes + ':' + seconds;
  }

  return (
    <div className="bg-white" style={{ backgroundColor: 'white' }}>
      <div id="myCarousel" className="carousel" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={img} style={{ width: "100%" }} />
            <div className="container">
              <div className="carousel-caption text-start"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="contianer bg-white">
        <div className="row col-lg-12 col-md-12 col-sm-12 col-xm-12 m-3 d-flex justify-content-center ">
          <h1 className="text-center m-3">የንግድ ሱቅ መምረጫ ቀጠሮ መረጃ መስጫ </h1>
          <div
            className="card shadow-lg p-3 bg-white rounded col-lg-8 col-md-8 col-sm-12 col-xm-12 d-flex justify-content-center"
            style={{ boxShadow: "0 4 8 0 rgba(0,0,0.2,0.9)" }}
          >
            <div className="card-body">
              <div
                className="row"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <label style={{ fontSize: "24px" }}>
                  የምዝገባ / የውል ቁጥርዎን ያስገቡ{" "}
                </label>
                <div className="row col-12 mt-1">
                </div>
                <div className="row d-flex justify-content-center">
                  <div className="input-group-append col-8">
                    <div className="col-12">
                      <input
                        required
                        type="text"
                        value={getTitle}
                        onChange={(e) => setGetTitle(e.target.value)}
                        className="form-control ml-2"
                        placeholder="RES/K/xxxx/xxxx"
                      />

                      <p className="text-danger">
                        {getTitle === "" ? "Required" : ""}
                      </p>
                    </div>

                  </div>
                  <div className="input-group-append col-4">
                    <button
                      className="btn btn-warning mr-2"
                      onClick={getDataByTitle}
                    >
                      ፈልግ
                    </button>
                    {/* <button
                    className="btn btn-sm btn-primary ml-5"
                    onClick={clearGetOutput}
                  >
                    Clear
                  </button> */}
                  </div>
                </div>
              </div>
            </div>
            {finalData === null ? (
              ""
            ) : getResult &&
              getResult !== undefined &&
              finalData.data.program_day !== "NOT ELIGIBLE" && finalData.data.site === "CMC"
              // finalData.data.per == '25'
              //  && finalData.data.site === "CMC"
              ? (
                <>
                  <div
                    className="alert alert-primary mt-2 text-black"
                    role="alert"
                  >
                    <div
                      style={{ fontSize: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 5 }}
                    >
                      <ul>
                        <li>
                          ስም - &nbsp; <u>{finalData.data.customer_name}</u>
                        </li>
                        <li>
                        የምዝገባ ቁጥር  - <u>{finalData.data.nag}</u>
                        </li>
                        <li>
                          ወለል -
                          <u>
                            {" "}
                            {finalData.data.floor} &nbsp;
                          </u>
                        </li>
                        <li>
                          ስፋት -
                          <u>
                            {" "}
                            {finalData.data.size} ካሬ
                          </u>
                        </li>
                        <li>
                          ሳይት -
                          <u>
                            {" "}
                            {finalData.data.site}{" "}
                          </u>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="alert alert-primary mt-2 text-black"
                    role="alert">
                    <h3 className="text-center">የሱቅ መምረጫ ቀጠሮ ፕሮግራም </h3>
                                        <hr />
                   

                    {/* <h3 className="text-center mt-4">ሱቅ ምርጫ ፕሮግራማችን  ለ አንድ ሳምንት ተራዝሟል !</h3> */}
                    <div
                      style={{ fontSize: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 5 }}
                    >
                      <ul>
                        <li>
                          ቦታ - <u>{finalData.data.site}</u>
                        </li>
                        <li>
                          ቀን- <u>{finalData.data.program_date}</u>
                        </li>
                        <li>
                          ሰዓት :{" "}
                          <u>{finalData.data.program_time.length > 8 ? finalData.data.program_time.replace(/:[^:]*$/, "") : finalData.data.program_time.length > 5 ? finalData.data.program_time.replace('AM', "") : finalData.data.program_time}</u>{" "}
                          &nbsp; በኢትዮጵያ አቆጣጠር{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              )
              : getResult &&
                getResult !== undefined &&
                finalData.data.site === "AYAT"
                // finalData.data.per >== "25" && finalData.data.site === "AYAT"
                ? (
                  <>
                    <div
                      className="alert alert-primary mt-2  text-black"
                      role="alert"
                    >
                      {/* <pre> {getResult}</pre> */}
                      {/* <pre>{finalData.data.id}</pre> */}
                      <div
                        style={{ fontSize: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 5 }}
                      >
                        <ul>
                          <li>
                            ስም - &nbsp; <u>{finalData.data.customer_name}</u>
                          </li>
                          <li>
                            የምዝገባ ቁጥር - <u>{finalData.data.nag}</u>
                          </li>
                          <li>
                            ወለል -
                            <u>
                              {" "}
                              {finalData.data.floor} &nbsp;
                            </u>
                          </li>
                          <li>
                            ስፋት -
                            <u>
                              {" "}
                              {finalData.data.size} ካሬ
                            </u>
                          </li>
                          <li>
                            ሳይት -
                            <u>
                              {" "}
                              {finalData.data.site}{" "}
                            </u>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className="alert alert-primary mt-2 text-center text-black"
                      role="alert"
                    >
                      <h3>የአያት ሳይት ፕሮግራም በቀጣይ ይወጣል</h3>
                    </div>
                  </>
                )
                : getResult &&
                getResult !== undefined &&
                finalData.data.site === "CANCEL"
                // finalData.data.per >== "25" && finalData.data.site === "AYAT"
                ? (
                  <>
                    <div
                      className="alert alert-primary mt-2  text-black"
                      role="alert"
                    >
                      {/* <pre> {getResult}</pre> */}
                      {/* <pre>{finalData.data.id}</pre> */}
                      <div
                        style={{ fontSize: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 5 }}
                      >
                        <ul>
                          <li>
                            ስም - &nbsp; <u>{finalData.data.customer_name}</u>
                          </li>
                          <li>
                            የምዝገባ ቁጥር - <u>{finalData.data.nag}</u>
                          </li>
                          <li>
                            ወለል -
                            <u>
                              {" "}
                              {finalData.data.floor} &nbsp;
                            </u>
                          </li>
                          <li>
                            ስፋት -
                            <u>
                              {" "}
                              {finalData.data.size} ካሬ
                            </u>
                          </li>
                          <li>
                            ሳይት -
                            <u>
                              {" "}
                              {finalData.data.site}{" "}
                            </u>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className="alert alert-primary mt-2 text-center text-black"
                      role="alert"
                    >
                      <h3>ምዝገባዎትን ሰርዘዋል</h3>
                    </div>
                  </>
                )
                : getResult &&
                getResult !== undefined &&
                finalData.data.site === "APARTMENT"
                // finalData.data.per >== "25" && finalData.data.site === "AYAT"
                ? (
                  <>
                    <div
                      className="alert alert-primary mt-2  text-black"
                      role="alert"
                    >
                      {/* <pre> {getResult}</pre> */}
                      {/* <pre>{finalData.data.id}</pre> */}
                      <div
                        style={{ fontSize: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 5 }}
                      >
                        <ul>
                          <li>
                            ስም - &nbsp; <u>{finalData.data.customer_name}</u>
                          </li>
                          <li>
                            የምዝገባ ቁጥር - <u>{finalData.data.nag}</u>
                          </li>
                          <li>
                            ወለል -
                            <u>
                              {" "}
                              {finalData.data.floor} &nbsp;
                            </u>
                          </li>
                          <li>
                            ስፋት -
                            <u>
                              {" "}
                              {finalData.data.size} ካሬ
                            </u>
                          </li>
                          <li>
                            ሳይት -
                            <u>
                              {" "}
                              {finalData.data.site}{" "}
                            </u>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      className="alert alert-primary mt-2 text-center text-black"
                      role="alert"
                    >
                      <h3>ምዝገባዎትን ወደ አፓርትመንት ለውጠዋል</h3>
                    </div>
                  </>
                )
                : getResult !== undefined &&
                  finalData.data.program_day === "NOT ELIGIBLE"  ?
              (
                  <>
                  <div
                      className="alert alert-primary mt-2 text-black"
                      role="alert"
                    >
                      {/* <pre> {getResult}</pre> */}
                      {/* <pre>{finalData.data.id}</pre> */}
                      <div
                        style={{ fontSize: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 5 }}
                      >
                        <ul>
                          <li>
                            ስም - &nbsp; <u>{finalData.data.customer_name}</u>
                          </li>
                          <li>
                            የምዝገባ ቁጥር - <u>{finalData.data.nag}</u>
                          </li>
                          <li>
                            ወለል -
                            <u>
                              {" "}
                              {finalData.data.floor} &nbsp;
                            </u>
                          </li>
                          <li>
                            ስፋት -
                            <u>
                              {" "}
                              {finalData.data.size} ካሬ
                            </u>
                          </li>
                          <li>
                            ሳይት -
                            <u>
                              {" "}
                              {finalData.data.site}{" "}
                            </u>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="alert alert-primary text-black mt-2" role="alert">
                      <h3 className="text-center">የሱቅ መምረጫ ቀጠሮ ፕሮግራም </h3>
                      <hr />
                      <div className="col" style={{ fontSize: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 5 }}>

                        <ul

                        >
                          <li>ሱቆን መርጠው ውል ለመፈራረም 25% ወይም ከዛ በላይ ያሟሉ</li>
                          <li>አስበልጦ የከፈለ ቅድሚያ ይኖረዋል</li>
                        </ul>
                      </div>
                    </div>
                  </>
                ) 
                : getResult !== undefined &&
                finalData.data.per > 25  ?
            (
                <>
                <div
                    className="alert alert-primary mt-2 text-black"
                    role="alert"
                  >
                    {/* <pre> {getResult}</pre> */}
                    {/* <pre>{finalData.data.id}</pre> */}
                    <div
                      style={{ fontSize: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 5 }}
                    >
                      <ul>
                        <li>
                          ስም - &nbsp; <u>{finalData.data.customer_name}</u>
                        </li>
                        <li>
                          የምዝገባ ቁጥር - <u>{finalData.data.nag}</u>
                        </li>
                        <li>
                          ወለል -
                          <u>
                            {" "}
                            {finalData.data.floor} &nbsp;
                          </u>
                        </li>
                        <li>
                          ስፋት -
                          <u>
                            {" "}
                            {finalData.data.size} ካሬ
                          </u>
                        </li>
                        <li>
                          ሳይት -
                          <u>
                            {" "}
                            {finalData.data.site}{" "}
                          </u>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="alert alert-primary text-black mt-2" role="alert">
                    <h3 className="text-center">የሱቅ መምረጫ ቀጠሮ ፕሮግራም </h3>
                    <hr />
                    <div className="col" style={{ fontSize: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 5 }}>

                      <ul

                      >
                        <li>ሱቆን መርጠው ውል ለመፈራረም 25% ወይም ከዛ በላይ ያሟሉ</li>
                        <li>አስበልጦ የከፈለ ቅድሚያ ይኖረዋል</li>
                      </ul>
                    </div>
                  </div>
                </>
              )
                : (
                  <div
                    className="alert alert-primary mt-2 text-danger"
                    role="alert"
                  >

                    {getResult === null ? (
                      ""
                    ) : finalData === null ? (
                      setGetResult(null)
                    ) : getTitle === "" ? (
                      setGetResult(null)
                    ) : (
                      <h3>{finalData.data}</h3>
                    )}
                  </div>
                )}
            <div className="alert alert-primary text-black mt-2" role="alert">
              <h1>
                <BsFillExclamationCircleFill /> ማሳሰቢያ:-{" "}
              </h1>
              <hr />
              <br />

              <ul
                style={{ fontSize: '24px' }}
              >

                <li>
                  {" "}
                  በተጠቀሰው የምርጫ ፕሮግራም ካልተገኙ ከእርሶ በኃላ ያሉ ተረኞችን ማስመረጥ
                  የምንቀጥል መሆኑን አውቀው በተመደበልዎ ሰዓት ይገኙ::
                </li>
                <li>
                  {" "}
                  ለምርጫ ሲመጡ የከፈሉበትን ደረሰኝ አና መታወቂያ ወይም ውክልና ይዘው ይቅረቡ::
                </li>
                <li>
                  {" "}
                  ለምርጫ ተዘጋጅተው እንዲመጡ ከታች ካለው ፍሎር ፕላን ላይ አማራጭ ሱቆችን መርጠው
                  ተዘጋጅተው ይምጡ::
                </li>

              </ul>

              <hr />
            </div>
            <div className=" d-flex justify-content-center">
              <div className="row col-4 d-flex justify-content-center align-items-center">
                <h3 className="text-center mb-3 mt-3">
                  {getResult &&
                    finalData.data.id !== undefined &&
                    finalData.data.program_day !== "NOT ELIGIBLE"
                    ? "Floor Plan"
                    : ""}
                </h3>

                <br />
                {getResult &&
                  finalData.data.id !== undefined &&
                  finalData.data.program_day !== "NOT ELIGIBLE" ? (
                  finalData.data.floor === "1F" ||
                    finalData.data.floor === "1f" ? (
                    <a
                      className="btn btn-warning mb-2"
                      href={`${ff}#toolbar=0`}
                      target="_blank"
                      rel="noopener noreferrer"
                      controlsList="nodownload"
                    >
                      First Floor
                    </a>
                  ) : finalData.data.floor === "3F" ||
                    finalData.data.floor === "3f" ? (
                    <a
                      className="btn btn-warning mb-2"
                      href={`${tf}#toolbar=0`}
                      target="_blank"
                      rel="noopener noreferrer"
                      controlsList="nodownload"
                    >
                      Third Floor
                    </a>
                  ) : finalData.data.floor === "2F" ||
                    finalData.data.floor === "2f" ? (
                    <a
                      className="btn btn-warning mb-2"
                      href={`${sf}#toolbar=0`}
                      target="_blank"
                      rel="noopener noreferrer"
                      controlsList="nodownload"
                    >
                      Second Floor
                    </a>
                    )
                    : finalData.data.floor === "LFF" ||
                    finalData.data.floor === "Lff" ||
                    finalData.data.floor === "lff" ? (
                    <a
                      className="btn btn-warning mb-2"
                      href={`${lff}#toolbar=0`}
                      target="_blank"
                      rel="noopener noreferrer"
                      controlsList="nodownload"
                    >
                      Lower First Floor
                    </a>
                  ) : finalData.data.floor === "GF" ||
                    finalData.data.floor === "gf" ? (
                    <a
                      className="btn btn-warning mb-2"
                      href={`${gf}#toolbar=0`}
                      target="_blank"
                      rel="noopener noreferrer"
                      controlsList="nodownload"
                    >
                      Ground Floor
                    </a>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <br />

          <div className="container d-flex justify-content-center align-items-center mb-2">
            <hr />
            <ReactLogo style={{ width: 400, height: 400 }} />
          </div>
          <div className="container">
            <div className="row p-5">
              <footer className="container">
                <p className="float-end">
                  <a href="#">Back to top</a>
                </p>
                <p>&copy; Ayat Real Estate 2022. All rights reserved.</p>
              </footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
